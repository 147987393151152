import React from "react"
import { Link } from "gatsby"
import { Box } from "grommet";
import Layout from "../components/layout"
import SEO from "../components/seo"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Features,
  Sign,
  Slider,
  ComingSoon,
} from "../components";


const IndexPage = () => (
  <Layout>
    <SEO title="Hyper Engine ⚡Blazingly Fast Car Dealer Websites" description="⚡Blazingly Fast Car Dealer Websites." />
        <Slider />
        <Box align="center" pad="large">
          <ComingSoon />
          <Sign />
          <Features />
        </Box>

  </Layout>
)

export default IndexPage
